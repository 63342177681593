import camelcaseKeys from 'camelcase-keys';
import type { WretchAddon, WretchResponseChain } from 'wretch';
import type { ZodSchema, z } from 'zod';
import { getResolverForContentType } from '../utils';

interface ValidAddonOptions {
  preprocess: ((val: unknown) => unknown) | false;
}

export interface ValidResolver {
  valid: <T, C extends ValidResolver, R, Schema extends ZodSchema>(
    this: C & WretchResponseChain<T, C, R>,
    schema: Schema,
    options?: Partial<ValidAddonOptions>,
  ) => Promise<z.infer<Schema>>;
}

export interface ValidAddonContext {
  valid: {
    preprocess: Exclude<ValidAddonOptions['preprocess'], false>;
    schema: ZodSchema;
  };
}

export const validAddonDefaultOptions: ValidAddonOptions = {
  preprocess: (val) => {
    return camelcaseKeys(val as Record<string, unknown>, {
      deep: true,
      preserveConsecutiveUppercase: true,
    });
  },
};

export const valid: (
  defaultOptions?: Partial<ValidAddonOptions>,
) => WretchAddon<unknown, ValidResolver> = (
  defaultOptions = validAddonDefaultOptions,
) => {
  return {
    resolver: {
      async valid(schema, options) {
        const finalOptions = {
          ...defaultOptions,
          ...options,
        };
        const preprocess = finalOptions.preprocess || ((val: unknown) => val);
        const getResolver = async () => {
          // Use this._fetchReq to make sure we resolve the response in catchers
          // https://github.com/elbywan/wretch/issues/219#issuecomment-1962480825
          const response = await this._fetchReq;
          const contentType = response.headers.get('content-type');
          return getResolverForContentType(contentType);
        };

        // Store the preprocessor and schema in the request options,
        // so that it can be used to replay the request in catchers:
        // https://github.com/elbywan/wretch/issues/219#issuecomment-1962480825
        this._wretchReq._options.valid = {
          preprocess,
          schema,
        } satisfies ValidAddonContext['valid'];

        const resolver = await getResolver();

        return this[resolver]((data) => {
          const validation = schema.safeParse(preprocess(data));

          if (!validation.success) {
            console.error(`[Valid] Failed ${this._wretchReq._url}`);
            console.error('[Valid]', validation.error);
            throw validation.error;
          }

          return validation.data;
        });
      },
    },
  };
};
