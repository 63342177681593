import wretch from 'wretch';
import { queryStringAddon } from 'wretch/addons';
import { valid } from './addons';

export interface CreateHTTPClientOptions {
  baseURL?: string;
  options?: RequestInit;
}

export function createHTTPClient({
  baseURL,
  options,
}: CreateHTTPClientOptions = {}) {
  return wretch(baseURL, options).addon(valid()).addon(queryStringAddon);
}

export * from './utils';

export type {
  Wretch as HTTPClient,
  WretchResponse as HTTPClientResponse,
  WretchError as HTTPClientError,
  Middleware as HTTPClientMiddleware,
  ConfiguredMiddleware as HTTPClientConfiguredMiddleware,
  WretchResponseChain as HTTPClientResponseChain,
} from 'wretch';
