import type { WretchResponseChain } from 'wretch';

type Resolver = keyof Pick<
  WretchResponseChain<unknown>,
  'json' | 'blob' | 'formData' | 'text'
>;

export function getResolverForContentType(
  contentType?: string | null,
): Resolver {
  const type = contentType?.toLowerCase();

  switch (true) {
    case type?.includes('application/json'):
      return 'json';
    case type?.includes('application/octet-stream'):
      return 'blob';
    case type?.includes('multipart/form-data'):
      return 'formData';
    default:
      return 'text';
  }
}
